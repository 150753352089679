import React from 'react'
import { FormattedMessage } from 'react-intl'

const LoginPage: React.FC = () => (
  <div>
    <FormattedMessage id="app.loginPage" />
  </div>
)

export default LoginPage
